exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ad-tsx": () => import("./../../../src/pages/ad.tsx" /* webpackChunkName: "component---src-pages-ad-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contact-confirm-tsx": () => import("./../../../src/pages/contact/confirm.tsx" /* webpackChunkName: "component---src-pages-contact-confirm-tsx" */),
  "component---src-pages-contact-eng-confirm-tsx": () => import("./../../../src/pages/contactEng/confirm.tsx" /* webpackChunkName: "component---src-pages-contact-eng-confirm-tsx" */),
  "component---src-pages-contact-eng-index-tsx": () => import("./../../../src/pages/contactEng/index.tsx" /* webpackChunkName: "component---src-pages-contact-eng-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-thanks-eng-tsx": () => import("./../../../src/pages/thanksEng.tsx" /* webpackChunkName: "component---src-pages-thanks-eng-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-titp-tsx": () => import("./../../../src/pages/titp.tsx" /* webpackChunkName: "component---src-pages-titp-tsx" */)
}

